#introduction{
    min-height: 600px;
    position: relative;
}

.introduction {
    letter-spacing: 1px;
    line-height: 48px;
    font-size: 30px;
    color: rgba(255,255,255,0.9);
}

.logo {
    margin-bottom: 25px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: min-content auto;
    align-items: end;
    grid-gap: 20px;
    font-size: 14px;
    color: rgba(255,255,255,0.7);
    font-family: 'Open Sans', sans-serif;
}


.open-positions {
    height: 200px;
    display: grid;
    place-items: center;
    margin: 50px 0 100px;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(var(--white-rgb), 0.2);
    }

    70% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

#apply-button {
    animation: fade-in ease-in-out 0.3s, pulse 2s infinite;
    background-color: rgba(var(--white-rgb), 0.3);
    color: var(--white);
    padding: 20px 80px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: bold;
    transition: 0.3s;
    z-index: 2;
    text-align: center;
}

.open-positions #apply-button:hover {
    background-color: var(--gray-6);
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fade-out {
    animation: fade-out ease-in-out 0.3s !important;
}

@keyframes transition-to-fab {
    0% {
        position: fixed;
        bottom: 100vh;
        right: 50%;
    }

    100% {
        bottom: 30px;
        right: 45px;
    }
}

#apply-button.fab {
    animation: transition-to-fab ease-in-out 0.6s, pulse 2s infinite;
    background-color: rgba(var(--black-rgb), 0.7);
    color: var(--white);
    position: fixed;
    bottom: 40px;
    right: 45px;
}

#apply-button.fab:hover {
    background-color: rgba(var(--black-rgb), 0.8);
}

/***********************************
			/* 1. BODY */
/***********************************/

@charset "UTF-8";

/***********************************
			/* 1. BODY */
/***********************************/

#webgl-canvas {
    background: radial-gradient(
            circle farthest-corner at center top,
            #071021,
            #19324a
    );
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100% !important;
    width: 100% !important;
}


.floating-jobs{
    position: relative;
    padding-bottom: 150px;
    height: 100px;
}

a.floating-job{
    border-radius: 50px;
    display: inline-block;
    cursor: pointer;
    background: white !important;
    color: black !important;
    position: absolute;
    padding: 15px 20px;
    opacity: 0;
    animation-iteration-count: infinite;
    animation-name: float-jobs;
    animation-fill-mode: forwards;
    text-align: center;
    font-size: 14px;
    transition: all 0.3s;
    max-width: 225px;
    animation-timing-function: linear;
}

.floating-jobs:hover a.floating-job{
    animation-play-state: paused;
}

/* +10% - you cannot use scale because transform is used to move it too */
.floating-jobs:hover a:hover{
    font-size: 16px;
    max-width: 250px;
    padding: 17px 22px;
    font-weight: bold;
}

@keyframes float-jobs {
    0% { transform: translateX(-400px); opacity: 1; }
    99.9% { transform: translateX(calc(100vw + 400px)); opacity: 1; }
    100% { transform: translateX(calc(100vw + 400px)); display: none; opacity: 0;}
}

@media (max-width: 900px) {
    .floating-jobs{
        display: none;
    }
}
