.tabs span.active{
    color: rgba(255, 255, 255, 1);
}

.tabs{
    user-select: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    letter-spacing: 0.3px;
    font-size: 18px;
    padding:0px;
    list-style:none;
    display:inline-block;
    position:relative;
}

.tabs span{
    text-decoration:none;
    color: rgba(255, 255, 255, 0.5);
    padding: 6px 12px;
    margin: 0;
    display:inline-block;
    position:relative;
    z-index:1;
    transition: 0.45s;
    cursor: pointer;
}

.tabs span:first-of-type{
    border-radius: 32px 0 0 32px;
}

.tabs span:last-of-type{
    border-radius: 0 32px 32px   0;
}

.tabs .selector{
    height:100%;
    display:inline-block;
    position:absolute;
    left:0px;
    top:0px;
    z-index:1;
    margin: 0 0 0 5px;
    border-radius:50px;
    transition-duration:0.45s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    background: rgba(var(--white-rgb), 0.2);
}
