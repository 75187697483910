.cards-wrap {
    background:blue;
    height: 180px;
    overflow-x: hidden;
}

.card {
    background: purple;
    width: 140px;
    height: 140px;
    display: inline-block;
    position:absolute;
    margin-top: 20px;
    left: -160px;
    color: white;
    font-size:30px;
    text-align: center;
    line-height: 70px;
}
