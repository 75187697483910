
@font-face {
  font-family: "jbmono";
  src: url("./assets/font/JetBrainsMono-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "jbmono";
  src: url("./assets/font/JetBrainsMono-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "jbmono";
  src: url("./assets/font/JetBrainsMono-Medium.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "jbmono";
  src: url("./assets/font/JetBrainsMono-Medium-Italic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
