.team-page{
    display: grid;
    font-family: 'Manrope', sans-serif;
    background: white;
    color: black;
    --page-padding-top-bottom: 80px;
    --page-padding-left-right: 100px;
}

.team-page > * {
    padding: var(--page-padding-top-bottom) var(--page-padding-left-right);
    width: 100vw;
}

map > area{
    background: rgba(255,0,0,0.2);
}

#team-introduction{
    background: #e2cebe;
    overflow: hidden;
    height: max-content;
    place-content: space-evenly;
    min-height: 90vh;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1.5fr;
    align-items: center;
    gap: 100px;
    justify-items: center;
}

#team-introduction > div.left{
    display: flex;
    gap: 15px;
    flex-direction: column;
    z-index: 2;
}

#composition{
    width: 100%;
    z-index: 1;
}

#team-welcome{
    grid-area: team-welcome;
    color: var(--gray-4);
}

#team-name{
    grid-area: team-name;
    place-self: start end;
}
#team-about{
    grid-area: team-about;
}

h1{
    color: black;
    font-size: 58px;
    letter-spacing: -3px;
}

#team-description{
    font-size: 18px;
    letter-spacing: -0.5px;
    color: rgba(var(--black-rgb), 0.7);
}

.dark #team-description{
    color: rgba(var(--white-rgb), 0.7);
}

#name{
    font-weight: 600;
    color: var(--orange-9);
}

#engineering{
    font-weight: 300;
    color: rgba(var(--black-rgb), 0.5);
}

.dark #engineering{
    color: rgba(var(--white-rgb), 0.5);
}

h3{
    font-size: 20px;
    margin: 0 0 20px;
    font-weight: 300;
    color: rgba(var(--black-rgb), 0.7);
}

.dark h3{
    color: rgba(var(--white-rgb), 0.7);
}

h2{
    font-size: 40px;
}

#people-list {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, 400px);
    justify-content: space-between;
    width: calc(100vw - 200px);
}

.person{
    margin: 15px 0 0;
    display: grid;
    background: var(--color-7);
    border-radius: 20px;
    padding: 20px;
    grid-template-rows: 26px max-content auto;
    grid-template-columns: 64px auto;
    grid-template-areas: 'picture  name'
                         'picture  role'
                         'interest interest';
    transition: 0.3s;
}

.team-pic{
    width: 100%;
    margin-top: 60px;
    border-radius: 20px;
}

.pic{
    grid-area: picture;
    border-radius: 50%;
    height: 48px;
}
.name{
    grid-area: name;
    font-size: 20px;
}

.role{
    grid-area: role;
    font-size: 14px;
    opacity: 0.7;
}

.interest{
    margin-top: 10px;
    grid-area: interest;
    font-size: 18px;
    letter-spacing: -0.5px;
    line-height: 24px;
}

p{
    font-size: 20px;
    letter-spacing: -0.8px;
    line-height: 30px;
    }

#interested{
    background: #fff8f5;
}

.interested-items{
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    width: calc(100vw - 200px);
    margin-top: 50px;
}

.interested-item{
    display: grid;
    background: white;
    border-radius: 8px;
    padding: 30px;
    place-items: center;
}

.interested-img{
    width: 84px;
}

.interested-title{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 15px;
}

.interested-title, .interested-description{
    text-align: center;
}

#challanges{
    background: #ffefe2;
}

#challanges p{
    line-height: unset;
    letter-spacing: 0;
    font-size: 24px;
    text-align: center;
    word-spacing: 5px;
}

#process{
    --primary-dark-color: #ff7538;
    --primary-light-color: #ffefe2;
}

#process-stages{
    display: grid;
    margin: 20px 0 0;
    border-top: 2px solid var(--gray-2);
    border-bottom: 2px solid var(--gray-2);
}

.stage{
    position: relative;
        display: grid;
    grid-auto-flow: column;
    grid-template-columns: 200px auto;
}

.stage:hover .interview-img-wrapper{
    background: var(--primary-light-color);
}

.stage:hover .interview-title-wrapper:after{
    background: var(--primary-dark-color);
}

.stage:hover .interview-description{
    background: var(--primary-light-color);
}


.interview-title-wrapper{
    padding: 50px 30px 50px 0;
    display: grid;
    grid-template-columns: 84px 64px;
    grid-template-areas: "inteview-title              interview-img"
                         "interview-title-description interview-img";
    gap: 5px 20px;
    border-right: 2px solid var(--gray-1);
    position: relative;
}

.interview-title-wrapper:before{
    content: '';
    height: 100%;
    width: 2px;
    background: var(--gray-1);
    position: absolute;
    right: 61px;
    top: 0;
    z-index: 1;
}

.interview-title-wrapper.first:before{
    top: 50%;
    height: 50%;
}

.interview-title-wrapper.last:before {
    height: 50%;
}

.interview-title-wrapper:after{
    content: '';
    position: absolute;
    left: 194px;
    top: calc(50% - 5px);
    width: 10px;
    height: 10px;
    border: 2px solid var(--primary-dark-color);
    background: white;
    transition: 0.3s;
    border-radius: 50%;
}

.interview-img-wrapper{
    transition: 0.3s;
    grid-area: interview-img;
    border-radius: 50%;
    background: var(--gray-2);
    padding: 12px;
    width: max-content;
    place-self: center;
    z-index: 2;

}
.interview-img{
    width: 32px;
}


.interview-title{
    grid-area: inteview-title;
    font-size: 16px;
    font-weight: 600;
    text-align: right;
    place-self: end;
}

.interview-title-description{
    grid-area: interview-title-description;
    font-size: 12px;
    text-align: right;
    place-self: start end;
}

.interview-description{
    text-align: center;
    place-items: center;
    display: grid;
    background: var(--gray-1);
    border-radius: 8px;
    width: 100%;
    margin: 30px;
    padding: 20px;
    transition: 0.3s;
}

#join-us{
    background: #fff9f4;
}

#email-button{
    margin: 30px 30px 0;
    display: grid;
    place-content: center;
    --button-color: rgba(var(--black-rgb), 0.6);
}

#email-button.dark{
   --button-color: var(--white);
}

#email-button a{
    text-align: center;
    padding: 15px 80px;
    border-radius: 50px;
    letter-spacing: -0.6px;
    transition: 0.3s;
    background: #ffa65f;
    color: var(--button-color);
    font-weight: 700;
    font-size: 21px;
}

#email-button.bright a:hover{
    filter: brightness(95%) saturate(120%);
}

#email-button a:hover{
    filter: brightness(115%) saturate(120%);
}

p {
    margin: 20px 0;
}


@media (max-width: 500px) {
    #people-list{
        grid-template-columns: repeat(auto-fill, 270px) !important;
    }
    #email-button a{
        padding: 25px 30px !important;
    }

    .interested-items{
        grid-template-columns: repeat(auto-fill, 270px) !important;
    }

    .stage{
        display: grid !important;
        grid-auto-flow: row !important;
        grid-template-columns: unset !important;
        place-items: center !important;
        background: var(--gray-0);
        padding: 0 15px;
        border-radius: 8px;
        margin: 15px 0;
    }

    .interview-title-wrapper:before{
        display: none;
    }

    #process-stages{
        border-top: unset;
        border-bottom: unset;
    }

    .interview-title-wrapper:after{
        display: none;
    }

    .interview-description{
        margin: 0 30px 30px !important;
    }

    .interview-title-wrapper{
        border-right: transparent !important;
    }
}

@media (max-width: 1100px) {
    #team-page{
        --page-padding-left-right: 50px;
        --page-padding-top-bottom: 40px;
    }

    #team-introduction{
        grid-auto-flow: row;
        grid-template-columns: 1fr;

    }

    #composition{
        width: 100%;
        max-width: 650px;
        margin-left: 0;
    }

    .interested-items{
        grid-template-columns: repeat(auto-fill, 400px);
        justify-content: center;
    }
}
