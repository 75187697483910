@import "colors.css";
@import "resets.css";
@import "fonts.css";
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

#root{
    overflow-x: hidden;
}

body {
    scroll-behavior: smooth;
    font-family: jbmono, monospace, Monospaced;
    background-color: #181828;
    color: var(--white);
    overflow-x: hidden;
}

.container {
    display: grid;
    width: 100vw;
    overflow-x: hidden;
}

.container > .block {
    padding: 70px 100px;
    width: 100vw;
}

.logo > img {
    padding: 8px;
    height: 54px;
    width: 54px;
    background-color: var(--gray-7);
    border-radius: 50%;
}

.title {
    font-size: 40px;
    color: #e1e1ff;
    letter-spacing: 1px;
}

.description {
    font-size: 22px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    letter-spacing: 0.5px;
    line-height: 29px;
}

.cli-semi-hidden {
    opacity: 0.4;
}

#us {
    background: -webkit-linear-gradient(to bottom, var(--gray-2) 0px, var(--gray-4) 100%);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, var(--gray-2) 0px, var(--gray-4) 100%); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#us .title {
    color: var(--gray-9);
}

#us .description {
    color: var(--gray-7);
    font-family: 'Open Sans', sans-serif;
}

#forter {
    z-index: 1;
    background-color: var(--blue-0);
}

#forter .title {
    color: var(--blue-9);
}

#forter .description {
    color: var(--blue-7);
    font-family: 'Open Sans', sans-serif;
}

#employees {
    background: white;
    padding-top: 35px;
    min-height: 330px;
    z-index: 2;
}

#employees .title {
    color: var(--gray-8);
}

#employees .description {
    color: var(--gray-7);
    font-family: 'Open Sans', sans-serif;
}

#statistics {
    background-color: #003c0c;
    padding-bottom: 85px;
}

#statistics .title {
    color: var(--green-0);
}

#statistics .description {
    color: var(--white);
    font-family: 'Open Sans', sans-serif;
}

#values {
    z-index: 1;
    background-color: var(--blue-0);
    background: -webkit-linear-gradient(to bottom, var(--blue-0) 60%, var(--white) 100%);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, var(--blue-0) 60%, var(--white) 100%); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding-bottom: 20px;
}

#values .title {
    color: var(--blue-9);
}

#values .description {
    color: var(--blue-7);
    font-family: 'Open Sans', sans-serif;
}

#tools {
    padding-top: 520px;
    padding-bottom: 100px;
    z-index: 1;
    background: -webkit-linear-gradient(to bottom, #370D20 0, #211C2D 100%);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #370D20 0, #211C2D 100%); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#tools .title {
    color: var(--white);
    margin-top: -270px;
}

#tools .description {
    color: var(--white);
    font-family: 'Open Sans', sans-serif;
}

#benefits {
    background: -webkit-linear-gradient(to bottom, #211C2D 0, #0B2B3A 100%);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #211C2D 0, #0B2B3A 100%); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    margin-top: -100px;
    padding-top: 120px;
    z-index: 2;
}

#benefits .title {
    color: var(--white);
}

#benefits .description {
    color: var(--white);
    font-family: 'Open Sans', sans-serif;
}

#office {
    background: -webkit-linear-gradient(to bottom, var(--white) 0px, var(--gray-2) 100%);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, var(--white) 0px, var(--gray-2) 100%); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#office .title {
    color: var(--gray-9);
}

#office .description {
    color: var(--gray-9);
    font-family: 'Open Sans', sans-serif;
}

.collage {
    height: 700px;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 2fr 2fr 1fr;
    gap: 25px;
    margin: 20px 0 0;
    grid-template-areas:
    "collage-1 collage-2 collage-4"
    "collage-1 collage-3 collage-4"
    "collage-5 collage-3 collage-6"
    "collage-5 collage-3 collage-6";
}

.collage > * {
    background-size: 100%;
    background-position: 50% 50%;
    font-family: 'Open Sans', sans-serif;
}

.collage > *::after {
    font-family: 'Open Sans', sans-serif;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: '';
    opacity: 0.5;
    transition: 0.3s opacity;
}

#collage-1 {
    grid-area: collage-1;
    background-image: url('./assets/images/us/forker.gif');
}

#collage-1::after {
    background: var(--yellow-5);
}

#collage-2 {
    grid-area: collage-2;
    background-image: url('./assets/images/us/3.png');
}

#collage-2::after {
    background: var(--red-5);
}

#collage-3 {
    grid-area: collage-3;
    background-image: url('./assets/images/us/kotlin.gif');
}

#collage-3::after {
    background: var(--orange-5);
}

#collage-4 {
    grid-area: collage-4;
    background-image: url('./assets/images/us/reimann.gif');
}

#collage-4::after {
    background: var(--grape-5);
}

#collage-5 {
    grid-area: collage-5;
    background-image: url('./assets/images/us/2.jpg');
}

#collage-5::after {
    background: var(--blue-5);
}

#collage-6 {
    grid-area: collage-6;
    background-image: url('./assets/images/us/1.jpg');
}

#collage-6::after {
    background: var(--green-5);
}

#collage-1:hover::after,
#collage-3:hover::after,
#collage-4:hover::after {
    opacity: 0.7;
    color: rgba(var(--black-rgb), 0.8);
    content: 'View Full Video';
    display: grid;
    place-items: center;
    cursor: pointer;
}

.employee-contributions {
    font-family: 'Open Sans', sans-serif;
    color: var(--gray-7);
}

.orb {
    padding: 25px;
    width: 550px;
    height: 200px;
    background: var(--white);
    box-shadow: 0px 5px 10px rgba(var(--black-rgb), 0.3);
    border-radius: 20px;
    display: inline-grid;
    grid-template-columns: 30px max-content 1fr;
    grid-template-rows: 30px auto max-content max-content;
    grid-template-areas: 'story-title   story-title   story-title'
                         'story-content story-content story-content'
                         'story-picture story-author  .'
                         'story-picture story-time  .';
    margin: 0 25px;
    float: left;
    transition: all .2s ease-out;
    cursor: pointer;
    border-bottom: 5px solid #bb1d64;
}

.story-title{
    grid-area: story-title;
    text-align: center;
    font-weight: bold;
    width: 500px;
}

.story-description{
    grid-area: story-content;
    width: 500px;
}

.story-picture{
    grid-area: story-picture;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    place-self: center start;
}

.story-author{
    grid-area: story-author;
    place-self: center start;
    margin-left: 10px;
}

.story-time{
    grid-area: story-time;
    place-self: center start;
    color: var(--gray-5);
    font-size: 11px;
    margin-left: 10px;
    margin-top: 2px;
}

.orb:hover{
    transform: rotate(0deg) !important;
    border-bottom: 4px solid #ed3b8b;
}

.orb:nth-child(even){
    transform: rotate(3deg);
}

.orb:nth-child(odd){
    transform: rotate(-3deg);
}

.marquee {
    height: 280px;
    width: calc(100vw - 0px);
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    left: -100px;
    user-select: none;
    margin-bottom: -200px;
}

.marquee--inner {
    display: grid;
    grid-auto-flow: column;
    gap: 50px;
    position: absolute;
    animation: marquee 60s linear infinite;
}

.marquee--inner:hover {
    animation-play-state: paused;
}

.marquee-span {
    display: grid;
    grid-auto-flow: column;
    gap: 50px;
    float: left;
    width: 50%;
    margin-top: 65px;
}

@keyframes marquee {
    0% { transform: translateX(0) }
    100% { transform: translateX(calc(-7750px + 100vw)) ; }
}


.employee-contribution{
    width: 350px;
    background: red;
}

.statistics {
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    margin-top: 50px;
    gap: 50px;
}

.stats-title {
    font-size: 30px;
}

.stats-description {
    font-family: 'Open Sans', sans-serif;
}

.values {
    font-family: 'Open Sans', sans-serif;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, max-content);
    margin: 50px 0 20px;
    gap: 30px;
}

.value {
    width: 500px;
    display: grid;
    grid-template-rows: min-content min-content min-content;
    gap: 15px;
    place-self: start center;
}

.value-icon {
    width: 112px;
    height: 112px;
    background-color: var(--white);
    border-radius: 50%;
    display: grid;
    place-items: center;
}

.value-icon > img {
    max-width: 64px;
    max-height: 64px;
}

.value-title {
    color: var(--blue-9);
    font-weight: 900;
    letter-spacing: 1.9px;
    font-size: 17px;
}

.value-description {
    font-size: 18px;
    color: var(--blue-7);
    line-height: 30px;
    letter-spacing: 0.5px;
}

#stack {
    margin: 60px 0 40px;
    place-self: center;
}

.benefits {
    font-family: 'Open Sans', sans-serif;
    display: grid;
    grid-auto-flow: column;
    place-items: start center;
    margin: 50px 0 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, max-content);
    gap: 30px;
}

.benefit {
    width: 500px;
    display: grid;
    gap: 15px;
    grid-template-rows: min-content min-content auto;
}

.benefit-title {
    color: var(--white);
    font-weight: 900;
    letter-spacing: 1.9px;
    font-size: 17px;
}

.benefit-description {
    font-size: 18px;
    color: var(--white);
    line-height: 30px;
    letter-spacing: 0.5px;
}

.benefit-icon {
    place-self: start;
    width: 112px;
    height: 112px;
    background-color: var(--white);
    border-radius: 50%;
    display: grid;
    place-items: center;
}

.benefit-icon img {
    max-width: 64px;
    max-height: 64px;
}

#map {
    height: 400px;
    margin: 30px auto -20px;
    border-radius: 5px;
}

.tech-stack {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 25px;
    margin: 50px 0 20px;
    user-select: none;
}

.tech-item {
    display: grid;
    place-items: center;
    gap: 5px;
}

.tech-item .circle {
    display: grid;
    place-items: center;
    width: 64px;
    height: 64px;
    background-color: rgba(var(--white-rgb), 1);
    border-radius: 50%;
    padding: 7px;
    transition: 0.3s background-color;
}

.tech-item img {
    max-width: 32px;
    max-height: 32px;
}

.tech-item span {
    text-align: center;
    opacity: 1;
    transition: 0.3s;
}

@media (max-width: 1860px) {
    .benefits{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(5, max-content);
    }

    .values {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, max-content);
    }
}

@media (max-width: 1230px) {
    .benefits, .values {
        justify-content: center;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(9, max-content);
    }
}

@media (max-width: 1150px) {
    .tech-stack {
        grid-template-columns: repeat(3, 1fr);
    }

}

@media (max-width: 850px) {
    .container > .block {
        padding: 70px 50px;
    }

    .marquee-span{
        margin-top: 20px;
    }

    .marquee{
        left: -50px;
    }

    .open-positions {
        height: 350px;
    }

    #apply-button.fab {
        left: calc(50% - 122px);
    }

    #apply-button {
        font-size: 25px;
    }

    .statistics {
        grid-auto-flow: row;
    }

    .logo {
        font-size: 25px;
    }

    .title {
        font-size: 35px;
        line-height: 50px;
    }

    .description {
        font-size: 18px;
        line-height: 40px;
        letter-spacing: 0.8;
    }

    .value,
    .benefit {
        width: auto;
    }

    .value-title,
    .benefit-title {
        font-size: 20px;
    }

    .value-description,
    .benefit-description {
        font-size: 20px;
        line-height: 35px;
        letter-spacing: 1.2;
    }

    .stats-title {
        text-align: center;
        font-size: 20px;
    }

    .stats-description {
        font-size: 15px;
    }

    .tech-stack {
        grid-template-columns: repeat(2, 1fr);
    }

    .tech-item span {
        font-size: 15px;
    }

    .collage {
        height: unset;
        grid-template-columns: auto;
        grid-template-rows: repeat(6, 1fr);
        grid-template-areas:
      "collage-1"
      "collage-2"
      "collage-3"
      "collage-4"
      "collage-5"
      "collage-6";
    }

    .collage > * {
        height: 400px;
    }
}

@media (max-width: 500px){
    .orb{
        width: 100vw;
        height: 250px;
        grid-template-rows: max-content auto 40px;
        row-gap: 20px;
    }

    .story-title, .story-description{
        width: calc(100vw - 50px);
    }
}

a{
    transition: 0.3s;
}

.visible{
    visibility: visible;
    opacity: 1;
}

.hidden{
    visibility: hidden;
    opacity: 0;
}

#go-to-team{
    display: block;
    text-align: center;
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    background-color: rgba(var(--white-rgb), 0.2);
    margin: 80px auto 0;
    border-radius: 50px;
    width: 100%;
    max-width: max-content;
    padding: 8px 50px;
    transition: 0.3s;
}

#go-to-team:hover{
    background-color: rgba(var(--white-rgb), 0.3);
}
